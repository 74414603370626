import request from '@/utils/request'

// 查询商家任务人气纪录列表
export function listTaskPopularityRecord(query) {
  return request({
    url: '/task/popularity/record/list',
    method: 'get',
    params: query
  })
}

// 查询商家任务人气纪录详细
export function getTaskPopularityRecord(id) {
  return request({
    url: '/task/popularity/record/' + id,
    method: 'get'
  })
}

// 新增商家任务人气纪录
export function addTaskPopularityRecord(data) {
  return request({
    url: '/task/popularity/record',
    method: 'post',
    data: data
  })
}

// 修改商家任务人气纪录
export function updateTaskPopularityRecord(data) {
  return request({
    url: '/task/popularity/record',
    method: 'put',
    data: data
  })
}

// 删除商家任务人气纪录
export function delTaskPopularityRecord(id) {
  return request({
    url: '/task/popularity/record/' + id,
    method: 'delete'
  })
}
